@import 'mixins.sass'

app-root
  background-color: #ffffff

html, body
  height: 100%
  overflow-x: hidden

*,
*::before,
*::after
  box-sizing: border-box
  background: none
  // flex-shrink: 0;
  text-decoration: none


/* Сброс стиля для ссылки */
a
  color: #000
  text-decoration: none !important
  white-space: nowrap


/* Убираем внутренние отступы */
ul,
ul[class],
ol[class]
  padding: 0
  margin: 0 !important

/* Убираем внешние отступы */
body,
h1,
h2,
h3,
h4,
p,
ul[class],
ol[class],
li,
figure,
figcaption,
blockquote,
dl,
dd
  margin: 0 !important


/* Выставляем основные настройки по-умолчанию для body */
body
  min-height: 100vh
  scroll-behavior: smooth
  text-rendering: optimizeSpeed
  line-height: 1.5


/* Удаляем стандартную стилизацию для всех ul и il, у которых есть атрибут class*/
ul[class],
ol[class],
ul,
li
  list-style: none
  padding: 0
  margin: 0

/* Упрощаем работу с изображениями */
img
  max-width: 100%
  display: block

/* Указываем понятную периодичность в потоке данных у article*/
article > * + *
  margin-top: 1em

/* Наследуем шрифты для инпутов и кнопок */
input,
button,
textarea,
select
  font: inherit


button
  padding: 0
  border: none
  color: inherit
  background-color: transparent
  cursor: pointer
  font-family: Inter, sans-serif
  font-size: 1rem
  font-style: normal
  font-weight: 700
  line-height: 160%

/* Удаляем все анимации и переходы для людей, которые предпочитают их не использовать */
@media (prefers-reduced-motion: reduce)
  *
    animation-duration: 0.01ms !important
    animation-iteration-count: 1 !important
    transition-duration: 0.01ms !important
    scroll-behavior: auto !important

body
  color: var(--gray-dark)
  font-family: Inter, sans-serif
  font-size: 1rem
  font-style: normal
  font-weight: 400
  line-height: 160%


