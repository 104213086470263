:root
  --blue-main: #3d4179
  --white-main: #ffffff
  --black-main: rgba(0, 0, 0, 0.87)
  --opacity: 0.30000001192092896

@import 'bootstrap/dist/css/bootstrap.css'
@import 'bootstrap/scss/_functions.scss'
@import 'bootstrap/scss/_variables.scss'
@import 'ngx-owl-carousel-o/lib/styles/scss/owl.carousel'
@import 'ngx-owl-carousel-o/lib/styles/scss/owl.theme.default'
@import 'resets'

$accordion-icon-active-color: white !important

.cont
  margin: 0 50px

@media (max-width: 700px)
  .cont
    margin: 0 25px

.contt
  margin: 0 50px
  float: left

@media (max-width: 700px)
  .contt
    margin: 0 25px


.cont
  margin: 0 50px

@media (max-width: 700px)
  .cont
    margin: 0 25px
    
.head
  margin: 1rem 0 !important

.head1
  text-align: center
  font-size: 20px

.head2
  text-align: center
  color: red
  font-size: 50px

.head3
  text-align: center
  color: red
  font-size: 20px

.head4
  text-align: center
  color: black
  font-size: 40px

.head5
  margin: 1rem 0 !important
  font-size: 20px

.blue-button
  color: var(--white-main) !important
  background-color: var(--blue-main) !important

.white-button
  color: var(--blue-main) !important
  background-color: var(--blue-main) !important

.custom-card
  position: relative
  display: grid
  .card-button
    display: flex
    flex-direction: column
    justify-content: flex-end
    padding: 0 2rem 2rem
  .card-thumb,
  .card-button
    grid-area: 1/2

.intro
  padding: 1.5rem 0
  color: var(--white-main)
  background-color: var(--blue-main)
  h2
    text-align: center

.media
  .icons
    display: flex
    flex-direction: row
    gap: 1rem
    .icon
      max-width: 80px






input[type=text]
  appearance: none
  border: none
  outline: none
  border: .2em solid var(--blue-main)
  background: rgba(var(--blue-main), .2)
  border-radius: .2em .2em 0 0
  padding: .4em
  color: var(--blue-main)

@media (max-width: 873px)
  .card-button
    padding: 0 1.5rem 1rem

.img-fluid
  max-width: 100%
  height: auto

// .button-outlined
//   display: inline-flex
//   padding: 1rem 2rem
//   justify-content: center
//   align-items: center
//   gap: 0.625rem
//   border-radius: 1.875rem

// .button-neutral
//   display: inline-flex
//   justify-content: center
//   align-items: center
//   gap: 0.625rem

// .button
//   font-family: Inter, sans-serif
//   font-size: 1rem
//   font-style: normal
//   font-weight: 700
//   line-height: 160%

// .h1
//   color: var(--gray-deep-dark)
//   font-family: Geologica, sans-serif
//   font-size: 4.0625em
//   font-style: normal
//   font-weight: 700
//   line-height: 130%

// .h2
//   color: var(--gray-dark)
//   font-family: Geologica
//   font-size: 2.25rem
//   font-style: normal
//   font-weight: 700
//   line-height: 130%

// .h3
//   color: var(--gray-dark)
//   font-family: Geologica
//   font-size: 1.5rem
//   font-style: normal
//   font-weight: 700
//   line-height: 150%

// .component-wrapper
//   padding: 6.75rem 0
//   display: flex
//   flex-direction: column
//   gap: 5.5rem


// @media (max-width: 768px)
//   .component-wrapper
//     padding: 3rem 0
//     flex-wrap: wrap
//     gap: 2rem
//   .h1
//     font-size: 2.7rem
//   .h2
//     font-size: 2rem


/* Importing Bootstrap SCSS file. */
@import 'bootstrap/scss/bootstrap'
